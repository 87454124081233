.main-body {
  padding: 2rem;
}

.instruction-card {
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #f1f1f1;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.button-text {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 30px 20px 30px 20px !important;
}

.btn-container {
  padding: 0rem 1.875rem 0rem 1.875rem;
  display: flex;
  align-items: center;
  height: 80px;
}
.lets-go-btn {
  composes: btn snugs-bg-1 color-dflt from global;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
}
