.CameraCaptureOuter {
  background: #ffffff !important;
}

/* Toogle switch css */

.switch-field {
  display: flex;

  /* margin-bottom: 36px; */
  overflow: hidden;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  label {
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  input:checked+label {
    background-color: #03f2d5;
    box-shadow: none;
    color: #243d4e;
  }

  label {
    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
}

#frameDiv {
  position: absolute;
  border: 2px solid #01e5cd;
  top: 3%;
  bottom: 6%;
  left: 6%;
  right: 6%;
  z-index: 1;
}

#frametextDiv {
  position: absolute;
  left: 2%;
  right: 2%;
  bottom: 2%;
  z-index: 99;
  color: white;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

#container-circles {
  /* bottom: 20%!important; */
  left: 42% !important;

  #outer-circle {
    background-color: #03f2d6 !important;
    height: 0px !important;
    width: 0px !important;
    left: 0px !important;
  }

  #inner-circle {
    background-color: #03f2d6 !important;
    background-image: url('../assets/images/CameraIcon.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 70px !important;
    width: 70px !important;
    left: 0px !important;
    top: 0px !important;
    margin: 0px !important;
    z-index: 999 !important;
  }
}

#ear_switch_div {
  position: absolute;
  right: 0px;
  top: 5px;
}

.EarTypeHead {
  position: absolute;

  /* top: 2%; */
  top: 0px;
  z-index: 999;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #000000;
  text-transform: uppercase;
  background-color: white;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.EarTypeHeadSpan {
  color: #007bfe;
  position: absolute;
}

.InstructionsBot {
  position: absolute;
  bottom: 2.5%;
  z-index: 999;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;

  a {
    color: white !important;

    /* text-decoration: underline!important; */
    border-bottom: 2px solid white !important;
  }
}

.camera_overlay_div {
  position: absolute;
  z-index: 1 !important;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  /* box-shadow: rgb(3, 242, 214) 0px 0px 0px 2px inset; */
  /* border: 50px solid rgba(0, 0, 0, 0.3); */
  border-color: rgba(0, 0, 0, 0.3);
  border-style: solid;
  background: transparent;
}

.borderDiv_cam {
  position: absolute;
  z-index: 999;
}

.rightTop_cam {
  transform: rotate(90deg);
}

.leftBottom_cam {
  transform: rotate(-90deg);
}

.rightBottom_cam {
  transform: rotate(-180deg);
}

/* Cameara */

.react-html5-camera-photo-fullscreen> {

  img,
  video {
    width: 100vw;
    height: 93vh !important;
  }
}

/* Loader css for Capture Page */

.CameraCaptureOuter {
  .hjFLZP {
    background: #ffffff !important;
    height: 110% !important;

    /* height: 50%!important;
      top: 50%!important; */
  }

  .display-error h1 {
    display: none;
  }

  .iJhIuy {
    color: white !important;
  }

  .kEjfVS {
    position: fixed !important;
    top: 40% !important;
  }
}

.wrngEarDivCam {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
  padding-bottom: 25px;
  border-bottom: 1px solid #f1f1f1;
}

.wrngEarDivCamMiddle {
  border-bottom: none;
  padding-bottom: 30px;
}

.reviewInstrsDivCam {
  button {
    font-size: 12px !important;
    font-weight: 300 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    text-align: center;
    color: #007bfe !important;
    background: transparent !important;
    border-color: transparent !important;
  }

  padding: 25px;
}

.react-confirm-alert .snugsTopBtn {
  width: 60% !important;
  margin-left: 20% !important;
  margin-right: 20% !important;
  border-radius: 20px;
  border: solid 1px #03f2d6 !important;
  background-color: #03f2d6 !important;
  height: 40px !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.67 !important;
  letter-spacing: 0.5px !important;
  text-align: center !important;
  color: #000000 !important;
}

.camera_overlay_InstructionDiv {
  position: absolute;
  left: 12%;
  right: 12%;
  z-index: 1001;
  background-color: white;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.2);
}

.camera_overlay_InstructionDiv_Image {
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 80px;
    height: 80px;
  }
}

.camera_overlay_InstructionDiv_Content {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Roboto-Light' !important;
  margin-bottom: 20px;
}

.cam_overlay_inst_btn {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #007bfe !important;
  background: transparent !important;
  border-color: transparent !important;
  text-transform: uppercase;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffffff;
}

.co_inst_arrd {
  position: absolute;
  z-index: 1001;
  left: 48%;
}

.reviewInstrsBtnCam {
  text-transform: uppercase;
}

.takePhotoErrPP {
  .react-confirm-alert-body {
    padding-top: 7px !important;
    padding-bottom: 0px !important;
  }

  h1 {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }

  .wrngEarDivCam {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    font-family: 'Roboto-Light' !important;
  }

  .outerRedHeader {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ff5063;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;
  }

  .errorLineSpan {
    width: 15%;
    height: 1px;
    border-bottom: 1px solid #ff5063;
    position: absolute;
    bottom: 0px;
    left: 43%;
  }
}

@media only screen and (min-device-width: 768px) {
  #container-circles {
    left: 45% !important;
  }
}