.modal-content {
  border: none;
}

.common-issues {
  opacity: 0.5;
  // font-family: CocogoosePro;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
}
