.configureContainer {
    /* margin-bottom: 80px; */
    padding: 25px !important;
    position: relative !important;
}

.configFooterRow {
    /* position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px; */
    background-color: #f9f9f9;
    border-top: 2px solid #edeced;
    height: 60px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.footerBtn {
    background: transparent !important;
    border: none !important;
    text-decoration: underline;
    color: #000000 !important;
    outline: none !important;
    height: 20px;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    padding: 0px !important;
}

.productImagesCarouselSelect {
    /* background: linear-gradient(to bottom, #f9f9f9, #f6f6f6);
    margin-top: 15px;
    min-height: 170px; */
    margin-top: 20px;
    box-shadow: 0px 1px 11px 0px rgba(225, 225, 225, 1);

    /* border: 1px solid rgba(0,0,0,.125); */
}

.prdSelTable {
    width: 100%;

    /* min-height: 160px; */

    td {
        width: 33%;
        vertical-align: middle;
        padding: 10px;
    }
}

.selPrdImgcfgDiv {
    padding: 10px;
    padding-right: 20px;
    border-right: 1px solid #e1e1e1;
}

/* .prdSelImgTd{
      border-right: 1px solid #e1e1e1;
  } */

/* .prdSelCntTd {
      padding-left: 10px;
      padding-right: 10px;
  } */

.selPrdImgcfg {
    width: 100%;
    margin: auto;
    max-height: 110px;
}

.pselHead {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.5px;
}

.pselCnt {
    font-family: "CocogooseProLight" !important;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    padding-bottom: 15px;
}

.productImagesCarousel {
    min-height: 180px;

    /* min-width: 100%; */
    /* background: linear-gradient(to bottom, #f9f9f9, #f6f6f6); */
    margin-top: 15px;
}

/* .productImagesCarousel::-webkit-scrollbar {
      display: none;
  } */

.productImagesCarouselCont {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cfgproductCardOuter {
    flex: 0 0 auto;
    width: 43% !important;

    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 7.5px;
    height: 100% !important;
}

.cfgproductCard {
    padding-left: 10px;
    padding-right: 10px;

    /* height: 100%!important; */
    min-height: 170px;
    box-shadow: 0px 1px 11px 0px rgba(225, 225, 225, 1);
}

.cfgprodTitle {
    font-family: "CocogooseProLight" !important;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;

    /* margin-bottom: 0.75rem; */
}

.cfgprodImg {
    width: 80%;
    padding: 3px;
    margin: auto;
}

/* .cfgproductCardOuter {
      float: left!important;
      height: 95%!important;
  } */

.react-multi-carousel-dot button {
    width: 20px !important;
    height: 7px !important;
    border-radius: 0px !important;
    border-width: 1px !important;
    border-color: silver !important;

    &:hover {
        background: #333 !important;
    }
}

.react-multi-carousel-dot--active button {
    background: #333 !important;
}

.react-multi-carousel-item {
    transform-style: unset !important;
}

.react-multi-carousel-list {
    padding-bottom: 20px !important;
}

/* .react-multi-carousel-dot-list li{
  
  } */

.stepDiv {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: 0.5px;
    font-family: "CocogooseProSemiLight" !important;
    margin-bottom: 5px;
}

.stepDescDiv {
    font-family: "CocogooseProLight" !important;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
}

.configBtn {
    width: 88px;
    height: 40px;
    border-radius: 20px;
    font-family: "CocogooseProSemiLight" !important;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    text-align: center;
}

.CheckIconImg {
    width: 20px;
    height: 18px;
    margin: auto !important;
}

.CameraIconImg {
    margin: auto !important;
}

.cmsBtn {
    width: 100%;

    /* margin-left: 6.5%;
    margin-right: 6.5%; */
    height: 48px;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
}

.stepRow {
    padding-bottom: 28px;
}

.step1Row {
    margin-top: 15px;
    border-bottom: 1px solid #f1f1f1;
}

.step2Row {
    padding-top: 28px;
    border-bottom: 1px solid #f1f1f1;
}

.step3Row {
    padding-top: 28px;
}

.cmsBtnRow {
    /* padding-top: 30px; */
    position: absolute;
    left: 25px;
    right: 25px;
    margin-top: 40px;
    bottom: 20px;
}

.noPairsTd,
.priceLabelTd {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #505050;
    font-family: "CocogooseProLight" !important;
}