.menuOuterContainer {
    padding: 16px 25px 25px 25px;
}

.menuCloseBtn {
    width: 14px;
    height: 14px;
}

.menuItem {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;

    a {
        font-size: 20px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #ffffff !important;
        font-family: 'CocogooseProSemiLight' !important;
        text-decoration: none !important;
    }
}

.basketOuter {
    position: relative;
}

.cartItemsLenSpan {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: -0.6px;
    color: #03f2d6 !important;
    font-family: 'Roboto-Light' !important;

    /* position: absolute; */
    bottom: -1px;
    left: 0px;
    vertical-align: bottom !important;
}

.hamBmenuIconBars {
    transform: scale(1.3, 1);
}