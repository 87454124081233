.instruction-card {
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #f1f1f1;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.subhead-text {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  padding-bottom: 8px;
  text-align: center;
  color: #000000;
}

.description-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.card-body {
  padding: 20pt 20px 32px 20px !important;
}

.img-container {
  padding-bottom: 32px;
}
.photo {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.oval-container-right {
  position: absolute;
  width: 25px;
  top: 5px;
  right: 6px;
}
.oval-container-left {
  position: absolute;
  width: 25px;
  top: 5px;
  right: 6px;
}

.left {
  padding-right: 7px;
  display: inline-block;
  height: 100%;
  width: 50%;
}
.right {
  padding-left: 7px;
  display: inline-block;
  height: 100%;
  width: 50%;
}

.relative {
  position: relative;
}
