.footerCol {
    background-color: #f9f9f9;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.footerColText {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #505050 !important;
    font-family: "Roboto-Light" !important;

    a {
        color: #505050 !important;
    }
}