.main-body {
  padding: 2rem;
}

.hello-info-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hello-info {
  composes: fs-16px from global;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: "Roboto-Light" !important;
}

.step-container {
  padding-bottom: 1.25rem;
}

.btn-container {
  padding: 0rem 1.875rem 0rem 1.875rem;
  display: flex;
  align-items: center;
  height: 80px;
}

.step-title {
  composes: fs-16px from global;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.step-description {
  composes: fs-14px from global;
  font-weight: 300;
  opacity: 0.8;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: "Roboto-Light" !important;
}

.lets-go-btn {
  composes: btn snugs-bg-1 color-dflt from global;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
}