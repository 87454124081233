@font-face {
  font-family: 'CocogooseProSemiLight';
  src: url('./assets/fonts/CocogooseProSemiLight.otf') format('woff');
}

@font-face {
  font-family: 'CocogooseProLight';
  src: url('./assets/fonts/CocogooseProLight.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto-Light.woff') format('woff');
}

body {
  background: white;
  font-family: 'CocogooseProSemiLight';
  color: #000000;
}

.bg-white {
  background-color: white !important;
}

.bg-f9f9 {
  background-color: #f9f9f9 !important;
}

.cocogoosrProLight {
  font-family: 'CocogooseProLight' !important;
}

.cocogoosrProSemiLight {
  font-family: 'CocogooseProSemiLight' !important;
}

.font-inherit {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.cursor-p {
  cursor: pointer !important;
}

.width-100 {
  width: 100% !important;
}

.color-dflt {
  color: #000000 !important;
}

.color-snugs1 {
  color: #03f2d6 !important;
}

.color-snugs2 {
  color: #243d4e !important;
}

.color-snugs3 {
  color: #505050 !important;
}

.color-snugs4 {
  color: #8445ff !important;
}

.snugs-bg-1 {
  background-color: #03f2d6 !important;
  border-color: #03f2d6 !important;
}

.snugs-bg-2 {
  background-color: #243d4e !important;
  border-color: #243d4e !important;
}

.snugs-bg-3 {
  background-color: #f5f5f5 !important;
}

.snugs-bg-btn-dis {
  background-color: rgba(3, 242, 214, 0.25) !important;

  /* border-color: rgba(3, 242, 214,0.25)!important; */
  border: none !important;
}

.fs-head {
  font-size: 1.2rem !important;
}

.fs-subhead {
  font-size: 1.1rem !important;
}

.fs-10px {
  font-size: 0.625rem !important;
}

.fs-12px {
  font-size: 0.75rem !important;
}

.fs-14px {
  font-size: 0.875rem !important;
}

.fs-16px {
  font-size: 1rem !important;
}

.fs-18px {
  font-size: 1.125rem !important;
}

.fs-20px {
  font-size: 1.25rem !important;
}

.fw-light {
  font-weight: lighter !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.snugsBigBtn {
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  width: 90% !important;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 24px;
}

.snugsXlBtn {
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  width: 95% !important;
  margin-left: 2.5%;
  margin-right: 2.5%;
  border-radius: 24px;
}

.snugsBtnmd {
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  width: 80% !important;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 24px;
}

.snugsBtnPlain {
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  border-radius: 24px;
}

.snugsBtnNrml {
  font-weight: 500 !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.25 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  border-radius: 24px;
  width: 100% !important;
}

.snugsTopBtn {
  background-color: #03f2d6 !important;
  border-color: #03f2d6 !important;
  color: #000000 !important;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 24px;
  text-transform: uppercase;
  min-width: 40%;
  margin: auto;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.opacity-01 {
  opacity: 0.1 !important;
}

.opacity-02 {
  opacity: 0.2 !important;
}

.opacity-03 {
  opacity: 0.3 !important;
}

.opacity-04 {
  opacity: 0.4 !important;
}

.opacity-05 {
  opacity: 0.5 !important;
}

.opacity-06 {
  opacity: 0.6 !important;
}

.opacity-07 {
  opacity: 0.7 !important;
}

.opacity-08 {
  opacity: 0.8 !important;
}

.opacity-085 {
  opacity: 0.85 !important;
}

.opacity-09 {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.pl-15pct {
  padding-left: 15% !important;
}

.pr-15pct {
  padding-right: 15% !important;
}

.pl-10pct {
  padding-left: 10% !important;
}

.pr-10pct {
  padding-right: 10% !important;
}

button:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.mb-fot {
  margin-bottom: 65px !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

/* React Alert custom css */

.react-confirm-alert-overlay {
  /* background: rgba(36, 61, 78)!important; */
  z-index: 1001 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.IconHeadConfirm {
  margin-bottom: 10px;
  margin-top: 10px;
}

.react-confirm-alert-body {
  background: transparent !important;
  box-shadow: none !important;
  text-align: center !important;
  width: 100% !important;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  font-family: 'CocogooseProLight' !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  h1 {
    text-align: center;
    color: #000000 !important;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    font-family: 'CocogooseProSemiLight' !important;
    margin-bottom: 15px !important;
  }

  a {
    color: rgb(3, 242, 213) !important;
    font-size: 0.875rem !important;
    text-decoration: none;
  }
}

.react-confirm-alert-button-group {
  text-align: center;
  display: block !important;

  button {
    width: 50%;
    text-align: center !important;
    background-color: rgb(3, 242, 213) !important;
    color: #243d4e !important;
    font-size: 1.2rem !important;
    margin-bottom: 15px;
    padding: 10px !important;
  }
}

.react-confirm-alert {
  position: absolute;
  bottom: 0px;
  background: #ffffff !important;

  /* padding-top: 15px;
  padding-bottom: 15px; */
  width: 100% !important;
  padding: 25px;
}

.halfMoonCrossImg {
  width: 56px;
  z-index: 1000;
  position: absolute;
  top: 0px;
  right: 0px;
}

.backArrowImgDiv {
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
  z-index: 999;
  font-size: 18px !important;
}

.CustomFormErrDiv {
  font-weight: 300;
  color: #c64e4e;
  font-size: 12px;
}

.CouponSuccessDiv {
  font-weight: 300;
  color: #28a745 !important;
  font-size: 12px;
}

.p-25px {
  padding: 25px !important;
}

.p-lr-25px {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.backArrSpan {
  position: absolute;
  left: 15px;
}

/* Few Old App Css which are necessary */

.HeadingFs {
  font-size: 1.5rem;
}

.min-h-800 {
  min-height: 800px !important;
}

.confptag {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

/* Rating Bar CSS */

.br-theme-bars-movie {
  .br-widget {
    height: 10px;
    white-space: nowrap;

    a {
      display: block;
      width: 13%;
      height: 8px;
      float: left;
      background-color: #bbcefb;
      margin: 1px;

      &.br-active,
      &.br-selected {
        /* background-color: #4278F5; */
        background-color: #03f2d5;
      }
    }

    .br-current-rating {
      clear: both;

      /* width: 240px; */
      width: 100%;
      text-align: center;
      font-weight: 600;
      display: block;
      padding: 0.5em 0;

      /* color: #4278F5; */
      color: #03f2d5;

      /* font-weight: 400; */
      font-size: 16px;
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active,
      &.br-selected {
        /* background-color: #729bf8; */
        background-color: #03f2d5;
      }
    }

    .br-current-rating {
      /* color: #729bf8; */
      color: #03f2d5;
    }
  }
}

@media print {
  .br-theme-bars-movie .br-widget {
    a {
      border: 1px solid #b3b3b3;
      background: white;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &.br-active,
      &.br-selected {
        border: 1px solid black;
        background: white;
      }
    }

    .br-current-rating {
      color: black;
    }
  }
}

/* Bounce Spinner CSS */

.spinner {
  margin: 50px auto 0;
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: #8445ff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

.plr20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.mb-32px {
  margin-bottom: 2rem;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Cookie Policy CSS */

.cookiePolicyCntDiv {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Roboto-Light' !important;
}

.cookiePolicyCntSpan {
  color: #007bfe !important;
}

.ppHeadDiv {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  margin: 25px 0px 10px 0px;
}

.ppCntDiv {
  opacity: 0.8;
  font-family: 'Roboto-Light' !important;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}

.pl-25px {
  padding-left: 25px !important;
}

.pr-25px {
  padding-right: 25px !important;
}

/* Custom Popup fadeout transitions */

.react-confirm-alert-overlay-fadeout {
  -webkit-animation: react-confirm-alert-fadeOut 0.5s 0.2s backwards !important;
  -moz-animation: react-confirm-alert-fadeOut 0.5s 0.2s backwards !important;
  -o-animation: react-confirm-alert-fadeOut 0.5s 0.2s backwards !important;
  animation: react-confirm-alert-fadeOut 0.5s 0.2s backwards !important;
}

@-webkit-keyframes react-confirm-alert-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-moz-keyframes react-confirm-alert-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-o-keyframes react-confirm-alert-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes react-confirm-alert-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.product-select-loader-custom-image {
  height: 1150px !important;

  // tab

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 470px !important;
  }

  // tab landscape
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    height: 623px !important;
  }

  // mobile
  @media (max-width: 668px) {
    height: 280px !important;
  }
}

.product-select-loader-custom {
  height: 268px !important;

  // tab

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 269px !important;
  }

  // tab landscape
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    height: 273px !important;
  }

  // mobile
  @media (max-width: 668px) {
    height: 273px !important;
  }
}

*:focus {
  outline: none;
}
