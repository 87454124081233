.alert-body {
  .error-title {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
  }
}
