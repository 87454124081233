.instHeadDiv {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    text-align: center;
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #f1f1f1 !important;
}

.InstcloseDiv {
    position: absolute;
    left: 25px;
}

.InstructionsOuterDiv {
    padding: 40px 25px 25px 25px;
}

.InstructionCard {
    border-radius: 4px;
    box-shadow: 0 0 12px 2px #f1f1f1;
    background-color: #ffffff;
    padding: 30px 20px 30px 20px !important;
    margin-bottom: 10px;
}

.InstructionCardNumber {
    font-size: 10px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.4;
    letter-spacing: normal;
    text-align: center;
    color: #8445ff;
    text-transform: uppercase;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.InstructionCardHead {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 8px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.InstructionCardContent {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    font-family: "Roboto-Light" !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.InstBotRow {
    margin-top: 20px;
    margin-bottom: 30px;
}

.instGotitBtn {
    height: 48px;
    border-radius: 24px;
    background-color: #03f2d6;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}