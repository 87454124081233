.iframe-style {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrapper {
    padding: 56.25% 0 0 0;
    position: relative
}

.spinner {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}