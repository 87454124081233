.homgTopBg {
  /* background-image: url("images/home/bg-man-right@2x.jpg");
  background-size: 100% 100%;
  width: 100%;
  min-height: 300px; */
  height: 48%;
}

.homgTopBgImage {
  width: 100%;
}

.headerHomeOuter {
  padding: 16px;
}

.homeTopSec2 {
  padding: 32px;
}

.homeTopSec2Cnt {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.homeTopSec2ShpNwBtn {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #03f2d6 !important;
  background-color: #03f2d6 !important;
  font-size: 12px;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: 52%;
  margin-left: 24%;
  margin-right: 24%;
  text-transform: uppercase;
}

.flagImgDiv {
  padding: 32px 0px 16px 0px !important;
}

.flagCntDiv {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
}

.snugsGoDiv {
  margin-bottom: 20px;
}

.homeTopSec2CntDiv {
  margin-bottom: 40px;
}

.bomperVideoOuter {
  margin: 15px 0px;
}

.scfBtn {
  /* margin-top: -20px; */
  width: 87%;
  margin-left: 6.5%;
  margin-right: 6.5%;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.smfcBtn {
  width: 66%;
  margin-left: 17%;
  margin-right: 17%;
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #243d4e !important;

  /* border: solid 1px #243d4e!important; */
  background-color: #ffffff !important;
  border-color: #243d4e !important;
}

.ccfDiv {
  width: 87%;
  margin-left: 6.5%;
  margin-right: 6.5%;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  margin-top: 48px;
}

.sendFCDiv {
  /* width: 87%;
  margin-left: 6.5%;
  margin-right: 6.5%; */
  opacity: 0.6;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;

  /* margin-top: 16px; */
  /* margin-bottom: 32px; */
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-bottom: 25px !important;
}

.bgman2Home {
  width: 100%;
}

.homeHeader {
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
}

.logo-header {
  width: 66px;
}

.scfDiv {
  position: fixed;
  bottom: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  z-index: 1;
  background-color: #ffffff;
}

.TopCnt2 {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  font-family: 'CocogooseProLight' !important;
  margin-top: 25px;
  margin-bottom: 10px;
}

.TopCnt3 {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
  margin-bottom: 15px;
}

.TopCnt4 {
  opacity: 0.8;
  font-family: 'Roboto-Light' !important;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 25px;
}

.smfcOuterDiv {
  background-color: #f9f9f9;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* .HomeOuter {
  margin-bottom: 120px!important;
  margin-bottom: 30px !important;
} */

.loveSnugsDiv {
  padding-left: 25px !important;
  padding-right: 25px !important;

  /* background-color: #f9f9f9; */
  padding-bottom: 30px;
}

.loveSnugsHeadDiv {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  padding-top: 30px !important;
}

.snugsLoveIconDiv {
  padding-top: 35px !important;
}

.loveSnugsInner {
  margin-top: 30px;
}

.loveSnugsHashDiv {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #a700ff;
  font-family: 'CocogooseProLight' !important;
  margin-bottom: 5px;
  display: none !important;
}

.loveSnugsSubHeadDiv {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}

.loveSnugsCntDiv {
  opacity: 0.6;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  font-family: 'Roboto-Light' !important;
}

.hIWDivOuter {
  padding: 25px 25px 50px 25px !important;
  background-color: #f9f9f9;
}

.hiwHead {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin-bottom: 25px;
}

.hiwCarousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  min-height: 200px;
  margin-top: 15px;
  scroll-behavior: smooth;
}

.hiwCarouselTitle {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.hiwCarouselContent {
  opacity: 0.6;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: 'CocogooseProLight' !important;
  margin-bottom: 10px;
}

.hiwCarouselCardOuter {
  padding: 10px;
  min-width: 200px !important;
}

.hiwCarouselIdOuter {
  position: relative;
  margin-bottom: 25px;
  height: 48px;
}

.hiwCarouselId {
  margin-bottom: 30px;
  position: absolute;
  top: 0px;
  left: 39%;
  width: 48px;
  height: 48px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: #243d4e;
  background-color: #f1f1f1;
  font-family: 'CocogooseProLight' !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 10px;
}

.hiwCarouselCard {
  padding: 10px;
  padding-top: 0px;
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #f1f1f1;
  background-color: #ffffff;
  min-height: 190px;
  position: relative;
}

.whatAreSnugsHead {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-bottom: 15px;
}

.whatAreSnugsDesc {
  opacity: 0.8;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
}

.earphonesImageDivCaption {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
  opacity: 0.8;
}

.earphonesImage {
  width: 100%;
}

.hIWDivHead {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-top: 10px;
}

.hiwCard {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #f1f1f1;
  background-color: #ffffff;
}

.hiwCard1 {
  margin-top: 30px;
}

.stepNumDiv {
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: #8445ff;
}

.hiwIconDiv {
  text-align: center;
  padding: 0px 0px 20px;
}

.hiwCardHead {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.hiwCardCnt {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
  padding-top: 10px;
}

.cardsMiddleDiv {
  margin-top: 15px;
  margin-bottom: 15px;
}

.hiwIcon {
  font-size: 18px !important;
}

.hiwBtnDiv {
  margin-top: 25px;
}

.hiwBtn {
  height: 40px;
  border-radius: 20px;
  border: 1px solid #03f2d6 !important;
  background-color: #03f2d6 !important;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
  width: 62%;
  margin-left: 18%;
  margin-right: 18%;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;

  /* padding: 10px 20px !important; */
  text-transform: uppercase;
}

.reBSOuter {
  margin-top: 30px;
}

.reBSHead {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'CocogooseProLight' !important;
}

.reBSContent {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'Roboto-Light' !important;
  margin-top: 5px;
  opacity: 0.6;
}

.reBsBtnDIv {
  margin-top: 30px;
}

.reBsBtn {
  height: 40px;
  border-radius: 20px;
  border-color: #03f2d6 !important;
  background-color: #03f2d6 !important;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000 !important;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  text-transform: uppercase;
}

@media only screen and (min-device-width: 768px) {
  .earphonesImage {
    width: 60%;
  }
}

.priceInfoOuter {
  margin: 30px 0px;
}

.priceInfoInnerDiv {
  text-align: center !important;
  padding: 0px 10px;
}

.priceInfoCntDiv {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  font-family: 'Roboto-Light' !important;
  margin: 15px 0px 0px;
}

/* Video React Player CSS */

.bomperVideoOuter {
  position: relative;

  .video-react-big-play-button {
    top: 43% !important;
    left: 43% !important;
    background: transparent !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 24px !important;
  }

  .video-react-poster {
    background-size: 100% 100% !important;
    background-color: transparent !important;
  }
}

.carousel {
  top: 20px;

  .carousel-caption {
    height: 120px;

    // tab landscape 
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      height: 160px;
    }

    // mobile 
    @media (max-width: 668px) {
      height: 160px;
    }

  }

  .carousel-indicators {
    background-color: white;

    li {
      background-color: #8445ff !important;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 100px;
    width: 100px;

    background-size: 100%, 100%;

    background-image: none;
  }

  .carousel-control-next-icon:after {
    content: '>';
    font-size: 55px;
    color: #8445ff;
  }

  .carousel-control-prev-icon:after {
    content: '<';
    font-size: 55px;
    color: #8445ff;
  }

  .custom-tag {
    max-width: 100%;
    height: 300px;


    // tab landscape 
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      height: 330px;
    }

    // mobile 
    @media (max-width: 668px) {
      height: 350px;
    }


    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 12px 2px #f1f1f1;

    .carousel-icon {
      text-align: center;
      padding: 45px 0px;

      img {
        height: 24px;
      }
    }

    .hiwBtn {
      height: 40px;
      border-radius: 20px;
      border: 1px solid #03f2d6 !important;
      background-color: #03f2d6 !important;
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: 0.5px;
      text-align: center;
      color: #000000;
      width: 62%;
      margin-left: 18%;
      margin-right: 18%;
      text-transform: uppercase;
      padding-left: 20px;
      padding-right: 20px;

      /* padding: 10px 20px !important; */
      text-transform: uppercase;
    }

    .carousel-caption {
      top: 70px;
      bottom: 0px;

      h3 {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: #000000;
        font-family: 'Roboto-Light' !important;
        padding-top: 10px;
      }



      // tab landscape 
      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        display: block !important;
      }

      // mobile 
      @media (max-width: 668px) {
        display: block !important;
      }
    }

    .carousel-link {
      margin-top: 90px;

      // tab landscape 
      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        margin-top: 100px;
      }

      // mobile 
      @media (max-width: 668px) {
        margin-top: 130px;
      }
    }
  }
}