.main-body {
  padding: 2rem;
}

.btn-container {
  padding: 0rem 1.875rem 0rem 1.875rem;
  display: flex;
  align-items: center;
  height: 80px;
}
.lets-go-btn {
  composes: btn snugs-bg-1 color-dflt from global;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
}

.mt {
  margin-top: 0.625rem;
}
