.sixDigitHeadDiv {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 15px 0px 15px;
}

.sixDigitDescDiv {
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Roboto-Light' !important;
  opacity: 0.8;
  padding-bottom: 2.5rem;
}

.sixDigitSec3 {
  padding: 40px 0px 40px;
}

.sixDigitIp {
  height: 48px !important;
  font-size: 1.3rem !important;
  text-align: center;
  letter-spacing: 1rem;

  &::placeholder {
    opacity: 0.1;
    color: #000000 !important;
  }
}

.sixDigitNSBtn {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000 !important;
  height: 40px;
  border-radius: 20px;
  border-color: #03f2d6 !important;
  background-color: #03f2d6 !important;
  padding-left: 20px;
  padding-right: 20px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  text-transform: uppercase;
}

.sixDigitNSdiv {
  position: absolute;
  bottom: 30px;
  margin-top: 30px;
}

.sixDigiterrDiv {
  color: red;
  text-align: center;
  font-size: 12px;
}

.cfProgressDivInner {
  background-color: #03f2d6;
  height: 4px;
}

.cfWelcomeSec3 {
  padding: 30px 0px 25px;
}

.cfWelcomeInstructionsDiv {
  .instHeadDiv,
  .InstBotRow {
    display: none !important;
  }

  .InstructionsOuterDiv {
    padding-top: 0px;
  }
}

.cfEPhRevInstDiv {
  margin: 20px 0px;

  a {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.5px;
    text-align: center;
    color: #007bfe !important;
  }
}

.cfEPhOuterDiv {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}

.cfEPhInnerDiv {
  border-radius: 4px;
  box-shadow: 0 0 12px 2px #f1f1f1;
  background-color: #ffffff;
  padding: 20px 15px;

  /* flex: 1; */
  flex-basis: 47%;
}

.cfEPhInnerHead {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  margin-top: 20px;
}

.cfEPhInnerCamDiv {
  margin-top: 30px;
}

.cfEPhInnerPhotoOutDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cfEPhInnerPhotoDiv {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  border: solid 0.5px #e1e1e1;
  background-color: #ffffff;
}

.cfEPhInnerPhotoImg {
  width: 48px;
  height: 48px;
}

.cfEPhSendPhotostDiv {
  position: absolute;
  bottom: 30px;
  margin-top: 30px;
}

.sendPhotosBtn {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000 !important;
  height: 40px;
  border-radius: 20px;
  border-color: #03f2d6 !important;
  background-color: #03f2d6 !important;
  padding-left: 20px;
  padding-right: 20px;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  text-transform: uppercase;
}
